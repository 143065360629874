import React, { Component } from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import classNames from "classnames"

import Typography from "@material-ui/core/Typography"

import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import PropTypes from "prop-types"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"

import Grid from "@material-ui/core/Grid"

// import hotel2 from "../images/commercial/hotel2.jpeg"
import hotel1 from "../images/commercial/hotel1.jpg"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

const cards = [
  {
    img: hotel1,
    title: "",
    details: "",
  },
  // { img: hotel2 },
]

function Commercial(props) {
  const { classes } = props

  return (
    <Layout>
      <div>
        <main>
          {/* Hero unit */}
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ fontSize: "56px" }}
              >
                Commercial Construction
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                We maintain and perform tenant improvements on over 100
                commercial buildings located in remote locations such as Dutch
                Harbor, Akutan, Egegik, Naknek, and Sandpoint Alaska. We
                confidently and proudly perform perform our high quality
                construction in conditions others refuse to work in.
              </Typography>

              <div className={classes.heroButtons}>
                <Grid container spacing={16} justify="center">
                  <Grid item>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Button variant="contained" color="primary" size="large">
                        Get a quote today!
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              {cards.map(card => (
                <Grid item key={card} xs={12} sm={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      alt={card.title}
                    />

                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>{card.details}</Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Link to={{ pathname: `/ada/${card.link}` }}>
                      <Button size="small" color="primary">
                        View
                      </Button>
                    </Link> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>
      </div>
    </Layout>
  )
}

Commercial.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Commercial)
